let instagramDisplay = 0;

if (window.outerWidth > 640) {
    instagramDisplay = 5;
} else {
    instagramDisplay = 4;
}

/*
<li class='column column-block'>
    <a href=\"{{link}}\"><img src=\"{{image}}\" /></a>
</li>
*/

var feed = new Instafeed({
    clientID: "e7154aa3bcf44834831749719e51227c",
    accessToken: "1667310470.e7154aa.6177f3174f964456be895bcd2781f1e7",
    get: "user",
    userId: 1667310470,
    limit: 60,
    resolution: "standard_resolution",
    template: `
        <div class="column">
            <a href="{{image}}" 
                class="instainfo" 
                target="_blank" 
                data-caption="{{caption}}" 
                data-username="{{model.user.username}}"
                data-profilepic="{{model.user.profile_picture}}"
                data-bio="{{model.user.bio}}"
                data-link="{{link}}"
                style="background-image:url('{{image}}');
            ">
            </a>
        </div>`,
    after: function () {
        $('#instafeed').slick({
            arrows: false,
            dots: false,
            focusOnSelect: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: 'linear',
            responsive: [{
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }]
        });

        $('.instainfo').click(function instaPopup(e) {
            // prevent hyperlink
            e.preventDefault();
            // setup variables from instagram data
            var $this = $(this);
            var $getUsername = $this.data('username');
            var $getCaption = $this.data('caption');
            var $getProfilePic = $this.data('profilepic');
            var $getBio = $this.data('bio');
            var $getLink = $this.data('link');
            var $getInstagramLink = '//www.instagram.com/rotiform/';
            var $getImage = $this.attr('href');

            // implement into popup
            $('#instagram-popup')
                .find('.instagram-popup-profilepic')
                .attr('src', $getProfilePic);
            $('#instagram-popup')
                .find('.instagram-popup-page')
                .attr('href', $getInstagramLink);
            $('#instagram-popup')
                .find('.instagram-popup-username')
                .html($getUsername)
                .attr('href', $getInstagramLink);
            $('#instagram-popup')
                .find('.instagram-popup-link')
                .attr('href', $getLink);
            $('#instagram-popup')
                .find('.instagram-popup-img')
                .attr('src', $getImage);
            $('#instagram-popup')
                .find('.instagram-popup-caption')
                .html($getCaption);

            // run magnific popup with variables set
            $.magnificPopup.open({

                items: {
                    src: '#instagram-popup',
                    type: 'inline'
                },
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1]
                }
            });
        });
    }
});
if ($(".instagram-container").length > 0) {
    feed.run();
}